(function () {
  'use strict';

  const io = window.io;

  SocketIO.$inject = ['$rootScope', '$timeout', 'AppUtils', 'Customer', '$translate'];

  angular.module('commonServices').service('socketIOService', SocketIO);

  function SocketIO($rootScope, $timeout, utils, Customer, $translate) {
    let socket;
    let waitingTask = [];

    let activeSubscriptions = [];

    this.init = init;
    this.emit = emit;
    this.isConnected = isConnected;
    this.subscribe = subscribe;
    this.unsubscribe = unsubscribe;

    function emit(event, params) {
      if (socket) {
        socket.emit(event, params, (err) => {
          if (err) {
            console.log(event, err);
          }
        });
      }
    }

    function isConnected() {
      return socket ? socket.connected : false;
    }

    function subscribe(entity, options) {
      if (!options || typeof options !== 'object') {
        return utils.Promise.resolve();
      }

      if (!socket?.connected) {
        waitingTask.push({ entity: entity, options: options, task: execSubscribe });
        return utils.Promise.resolve();
      } else {
        options.socketId = socket.id;
        return execSubscribe({ entity: entity, options: options });
      }
    }

    function unsubscribe(entity, options) {
      if (!options || typeof options !== 'object') {
        return utils.Promise.resolve();
      }

      if (!socket?.connected) {
        waitingTask.push({ entity: entity, options: options, task: execUnsubscribe });
        return utils.Promise.resolve();
      } else {
        options.socketId = socket.id;
        return execUnsubscribe({ entity: entity, options: options });
      }
    }

    function init(url, token) {
      if (!io || window.rtPath === undefined) {
        console.log('Socket.IO not loaded');
        waitingTask = [];
        return;
      }

      console.log('INIT', url);
      socket = io(url + '?access_token=' + token, {
        transports: ['websocket'],
        reconnectionDelay: 3000,
      });

      socket.on('connect', function () {
        console.log('connected');

        $timeout(() => {
          $rootScope.appErrorMessage = null;
        });

        waitingTask.forEach((config) => {
          config.options.socketId = socket.id;
          config.task(config);
        });

        activeSubscriptions.forEach((config) => {
          subscribe(config.entity, config.options);
        });

        waitingTask = [];
      });

      socket.on('disconnect', function () {
        console.log('disconnected');
        $timeout(() => {
          $rootScope.appErrorMessage = {
            type: 'socket.io',
            level: 'warning',
            text: $translate.instant('general.socket_io.disconnection'),
            show: true,
          };
        });

        waitingTask = [];
      });

      initEvents(socket);
    }

    function execSubscribe(config) {
      let promise;

      const options = {
        id: $rootScope.customerId,
        socketId: config.options.socketId,
      };

      if (['Asset', 'Device'].indexOf(config.entity.modelName) !== -1) {
        options.where = {
          id: { inq: Array.isArray(config.options.id) ? config.options.id : [config.options.id] },
        };
      }

      if (config.entity.modelName === 'Asset') {
        promise = Customer.prototype$subscribeAssets(options).$promise;
      } else if (config.entity.modelName === 'Device') {
        promise = Customer.prototype$subscribeDevices(options).$promise;
      } else {
        promise = config.entity.prototype$subscribe({
          id: config.options.id,
          socketId: config.options.socketId,
        }).$promise;
      }

      return promise
        .then((result) => {
          addSubscription(config);
          const ids = Array.isArray(config.options.id) ? config.options.id : [config.options.id];

          ids.forEach((id) => {
            emit('subscribe', {
              modelName: config.entity.modelName,
              modelId: id,
              channels: config.options.channels || { data: true },
            });
          });
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          console.log(err);
        });
    }

    function execUnsubscribe(config) {
      let promise;
      const options = {
        id: $rootScope.customerId,
        socketId: config.options.socketId,
      };

      let ids = [];
      if (['Asset', 'Device'].indexOf(config.entity.modelName) !== -1) {
        ids = Array.isArray(config.options.id) ? config.options.id : [config.options.id];
      }

      if (ids.length) {
        const chunks = utils.arrayToChunks(ids, 100);
        let func;
        if (config.entity.modelName === 'Asset') {
          func = Customer.prototype$unsubscribeAssets;
        } else if (config.entity.modelName === 'Device') {
          func = Customer.prototype$unsubscribeDevices;
        }
        const requests = [];
        for (let chunk of chunks) {
          const params = angular.copy(options);
          params.where = { id: { inq: chunk } };
          requests.push({ function: func, args: [params] });
        }

        promise = utils.parallelPromises(requests);
      } else {
        promise = config.entity.prototype$unsubscribe({
          id: config.options.id,
          socketId: config.options.socketId,
        }).$promise;
      }
      promise
        .then((result) => {
          removeSubscription(config);
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          console.log(err);
        });
    }

    function addSubscription(config) {
      const index = activeSubscriptions.findIndex((current) => {
        return (
          current.entity.modelName === config.entity.modelName &&
          JSON.stringify(current.options.id) === JSON.stringify(config.options.id)
        );
      });

      if (index === -1) {
        activeSubscriptions.push(config);
      } else {
        activeSubscriptions[index] = config;
      }
    }

    function removeSubscription(config) {
      const index = activeSubscriptions.findIndex((current) => {
        return (
          current.entity.modelName === config.entity.modelName &&
          JSON.stringify(current.options.id) === JSON.stringify(config.options.id)
        );
      });

      if (index !== -1) {
        activeSubscriptions.splice(index, 1);
      }
    }

    function initEvents(socket) {
      /* const onevent = socket.onevent;
      socket.onevent = function (packet) {
        var args = packet.data || [];
        onevent.call(this, packet); // original call
        packet.data = ['*'].concat(args);
        onevent.call(this, packet); // additional call to catch-all
      }; */

      /* socket.on('*', function (event, data) {
        console.log(event);
        console.log(data);
      }); */

      /**
       * updates
       */
      socket.on('Asset.change', onGeneralMessage);
      socket.on('Asset.container.update', onGeneralMessage);
      socket.on('Asset.healthStatus', onGeneralMessage);
      socket.on('Project.assets.healthStatus', (event) => {
        const type = 'Asset.healthStatus';
        for (let assetId in event.body) {
          onGeneralMessage({
            through: { model: 'Asset', id: assetId },
            from: { model: 'Asset', id: assetId },
            type,
            body: event.body[assetId],
          });
        }
      });

      /**
       * Data
       */
      socket.on('customer.data', onData);
      socket.on('asset.data', onData);
      socket.on('sensor.data', onData);

      /**
       * Summaries
       */
      socket.on('asset.summaries', onData);
      socket.on('sensor.summaries', onData);

      /**
       * Alerts
       */
      socket.on('sensor.alerts', onAlert);

      /**
       * UptimeCollector Create
       */
      socket.on('Customer.projects.assets.sensors.uptimeCollectors.create', onUptimeCollector);
      socket.on('Project.assets.sensors.uptimeCollectors.create', onUptimeCollector);
      socket.on('Asset.sensors.uptimeCollectors.create', onUptimeCollector);

      /**
       * Events Create
       */
      socket.on('Customer.events.create', onAssetEvent);
      socket.on('Asset.events.create', onAssetEvent);
      socket.on('EventTrigger.events.create', onAssetEvent);

      /**
       * Events Update
       */
      socket.on('Customer.events.update', onAssetEvent);
      socket.on('Asset.events.update', onAssetEvent);
      socket.on('EventTrigger.events.update', onAssetEvent);

      /**
       * Events Data Create
       */
      socket.on('Customer.events.data.create', onAssetEvent);
      socket.on('Asset.events.data.create', onAssetEvent);
      socket.on('EventTrigger.events.data.create', onAssetEvent);

      /**
       * Events Data Upload
       */
      socket.on('Customer.events.data.upload', onAssetEvent);
      socket.on('Asset.events.data.upload', onAssetEvent);
      socket.on('EventTrigger.events.data.upload', onAssetEvent);

      /**
       * Events StateChanges create
       */
      socket.on('Customer.events.stateChanges.create', onAssetEvent);
      socket.on('Asset.events.stateChanges.create', onAssetEvent);
      socket.on('EventTrigger.events.stateChanges.create', onAssetEvent);

      /**
       * Events StateChanges update
       */
      socket.on('Customer.events.stateChanges.update', onAssetEvent);
      socket.on('Asset.events.stateChanges.update', onAssetEvent);
      socket.on('EventTrigger.events.stateChanges.update', onAssetEvent);

      /**
       * Events comment
       */
      socket.on('Customer.events.comments.create', onAssetEvent);
      socket.on('Asset.events.comments.create', onAssetEvent);
      socket.on('EventTrigger.events.comments.create', onAssetEvent);

      /**
       * Device Events Create
       */
      socket.on('Customer.devices.events.create', onDeviceEvent);
      socket.on('Device.events.create', onDeviceEvent);

      /**
       * Device Events Update
       */
      socket.on('Customer.devices.events.update', onDeviceEvent);
      socket.on('Device.events.update', onDeviceEvent);

      /**
       * Device Events Data Create
       */
      socket.on('Customer.devices.events.data.create', onDeviceEvent);
      socket.on('Device.events.data.create', onDeviceEvent);

      /**
       * Device Events StateChanges create
       */
      socket.on('Customer.devices.events.stateChanges.create', onDeviceEvent);
      socket.on('Device.events.stateChanges.create', onDeviceEvent);

      /**
       * Device Events StateChanges update
       */
      socket.on('Customer.devices.events.stateChanges.update', onDeviceEvent);
      socket.on('Device.events.stateChanges.update', onDeviceEvent);

      /**
       * Device Events comment
       */
      socket.on('Customer.devices.comments.create', onDeviceEvent);
      socket.on('Device.events.comments.create', onDeviceEvent);

      /**
       * Healthcheck Events Create
       */
      socket.on('Customer.healthcheckEvents.create', onHealthcheckEvent);
      socket.on('Project.healthcheckEvents.create', onHealthcheckEvent);
      socket.on('Asset.healthcheckEvents.create', onHealthcheckEvent);

      /**
       * Healthcheck Events Update
       */
      socket.on('Customer.healthcheckEvents.update', onHealthcheckEvent);
      socket.on('Project.healthcheckEvents.update', onHealthcheckEvent);
      socket.on('Asset.healthcheckEvents.update', onHealthcheckEvent);

      /**
       * Healthcheck Events StateChanges create
       */
      socket.on('Customer.healthcheckEvents.stateChanges.create', onHealthcheckEvent);
      socket.on('Project.healthcheckEvents.stateChanges.create', onHealthcheckEvent);
      socket.on('Asset.healthcheckEvents.stateChanges.create', onHealthcheckEvent);

      /**
       * Healthcheck Events StateChanges update
       */
      socket.on('Customer.healthcheckEvents.stateChanges.update', onHealthcheckEvent);
      socket.on('Project.healthcheckEvents.stateChanges.update', onHealthcheckEvent);
      socket.on('Asset.healthcheckEvents.stateChanges.update', onHealthcheckEvent);

      /**
       * Healthcheck Events comment
       */
      socket.on('Customer.healthcheckEvents.comments.create', onHealthcheckEvent);
      socket.on('Project.healthcheckEvents.comments.create', onHealthcheckEvent);
      socket.on('Asset.healthcheckEvents.comments.create', onHealthcheckEvent);
    }

    function onGeneralMessage(event) {
      $rootScope.$broadcast(event.type, event);
    }

    function onData(data) {
      let event = 'io:' + data.type.substring(data.type.indexOf('.') + 1);
      $rootScope.$apply(function () {
        switch (data.type) {
          case 'customer.data':
          case 'customer.summaries':
            for (let assetId in data.body) {
              for (let sensorId in data.body[assetId]) {
                broadcastData(data.body[assetId][sensorId], sensorId, event);
              }
            }
            break;
          case 'asset.data':
          case 'asset.summaries':
            for (let sensorId in data.body) {
              broadcastData(data.body[sensorId], sensorId, event);
            }
            break;
          case 'sensor.data':
          case 'sensor.summaries':
            let content = data.body;
            let sensorId = data.from.id;
            broadcastData(content, sensorId, event);
            break;
          default:
            console.log(data);
        }
      });
    }

    function broadcastData(data, sensorId, event) {
      console.log(data);

      if (!data || typeof data !== 'object') {
        return;
      }
      if (!(data instanceof Array)) {
        data = Object.values(data);
      }

      for (let current of data) {
        current.from = new Date(current.from);
        current.to = new Date(current.to);
        current.created = new Date(current.created);
        broadcast(sensorId, current, event);
      }
    }

    function onAlert(data) {
      $rootScope.$apply(function () {
        const alert = data.body;
        alert.from = new Date(alert.from);
        alert.to = new Date(alert.to);
        broadcast(data.from.id, alert, 'io:alert');
      });
    }

    function onAssetEvent(data) {
      let event = 'io:' + data.type.substring(data.type.indexOf('.') + 1);
      $rootScope.$apply(function () {
        switch (data.type) {
          case 'Customer.events.create':
          case 'Customer.events.update':
          case 'Customer.events.comments.create':
          case 'Customer.events.data.create':
          case 'Customer.events.data.upload':
          case 'Customer.events.stateChanges.create':
          case 'Customer.events.stateChanges.update':
            for (let assetId in data.body) {
              for (let eventTriggerId in data.body[assetId]) {
                for (let eventId in data.body[assetId][eventTriggerId]) {
                  broadcastEvent(data.body[assetId][eventTriggerId][eventId], eventId, event);
                }
              }
            }
            break;
          case 'Asset.events.create':
          case 'Asset.events.update':
          case 'Asset.events.comments.create':
          case 'Asset.events.data.create':
          case 'Asset.events.data.upload':
          case 'Asset.events.stateChanges.create':
          case 'Asset.events.stateChanges.update':
            for (let eventTriggerId in data.body) {
              for (let eventId in data.body[eventTriggerId]) {
                broadcastEvent(data.body[eventTriggerId][eventId], eventId, event);
              }
            }
            break;
          case 'EventTrigger.events.create':
          case 'EventTrigger.events.update':
          case 'EventTrigger.events.comments.create':
          case 'EventTrigger.events.data.create':
          case 'EventTrigger.events.data.upload':
          case 'EventTrigger.events.stateChanges.create':
          case 'EventTrigger.events.stateChanges.update':
            for (let eventId in data.body) {
              broadcastEvent(data.body[eventId], eventId, event);
            }
            break;
          default:
            console.log(data);
        }
      });
    }

    function onUptimeCollector(data) {
      let event = 'io:sensors.uptimeCollectors.create';
      $rootScope.$apply(function () {
        switch (data.type) {
          case 'Customer.projects.assets.sensors.uptimeCollectors.create': {
            for (let projectId in data.body) {
              for (let assetId in data.body[projectId]) {
                for (let sensorId in data.body[projectId][assetId]) {
                  broadcast(sensorId, data.body[projectId][assetId][sensorId], event, 'sensorId');
                }
              }
            }
            break;
          }
          case 'Project.assets.sensors.uptimeCollectors.create': {
            for (let assetId in data.body) {
              for (let sensorId in data.body[assetId]) {
                broadcast(sensorId, data.body[assetId][sensorId], event, 'sensorId');
              }
            }
            break;
          }

          case 'Asset.sensors.uptimeCollectors.create': {
            for (let sensorId in data.body) {
              broadcast(sensorId, data.body[sensorId], event, 'sensorId');
            }
            break;
          }

          default:
            console.log(data);
        }
      });
    }

    function onDeviceEvent(data) {
      $rootScope.$apply(function () {
        switch (data.type) {
          case 'Customer.devices.events.create':
          case 'Customer.devices.events.update':
          case 'Customer.devices.events.comments.create':
          case 'Customer.devices.events.stateChanges.create':
          case 'Customer.devices.events.stateChanges.update': {
            let event = 'io:' + data.type.substring(data.type.indexOf('.') + 1);
            for (let deviceId in data.body) {
              for (let eventId in data.body[deviceId]) {
                broadcastEvent(data.body[deviceId][eventId], eventId, event, 'deviceEventId');
              }
            }
            break;
          }
          case 'Device.events.create':
          case 'Device.events.update':
          case 'Device.events.comments.create':
          case 'Device.events.stateChanges.create':
          case 'Device.events.stateChanges.update': {
            let event = 'io:devices.' + data.type.substring(data.type.indexOf('.') + 1);
            for (let eventId in data.body) {
              broadcastEvent(data.body[eventId], eventId, event, 'deviceEventId');
            }
            break;
          }

          case 'Device.events.data.create':
          case 'Device.events.data.upload': {
            let event = 'io:devices.' + data.type.substring(data.type.indexOf('.') + 1);
            for (let eventId in data.body) {
              for (let eventDataId in data.body[eventId]) {
                broadcastEvent(data.body[eventId][eventDataId], eventId, event, 'deviceEventId');
              }
            }
            break;
          }

          default:
            console.log(data);
        }
      });
    }

    function onHealthcheckEvent(data) {
      const event = 'io:' + data.type.substring(data.type.indexOf('.healthcheckEvents') + 1);
      $rootScope.$apply(function () {
        switch (data.type) {
          case 'Customer.healthcheckEvents.create':
          case 'Customer.healthcheckEvents.update':
          case 'Customer.healthcheckEvents.comments.create':
          case 'Customer.healthcheckEvents.stateChanges.create':
          case 'Customer.healthcheckEvents.stateChanges.update':
            for (let projectId in data.body) {
              for (let assetId in data.body[projectId]) {
                for (let healthcheckId in data.body[projectId][assetId]) {
                  broadcastEvent(
                    data.body[projectId][assetId][healthcheckId],
                    healthcheckId,
                    event,
                    'healthcheckEventId'
                  );
                }
              }
            }
            break;
          case 'Project.healthcheckEvents.create':
          case 'Project.healthcheckEvents.update':
          case 'Project.healthcheckEvents.comments.create':
          case 'Project.healthcheckEvents.stateChanges.create':
          case 'Project.healthcheckEvents.stateChanges.update':
            for (let assetId in data.body) {
              for (let healthcheckEventId in data.body[assetId]) {
                broadcastEvent(
                  data.body[assetId][healthcheckEventId],
                  healthcheckEventId,
                  event,
                  'healthcheckEventId'
                );
              }
            }
            break;
          case 'Asset.healthcheckEvents.create':
          case 'Asset.healthcheckEvents.update':
          case 'Asset.healthcheckEvents.comments.create':
          case 'Asset.healthcheckEvents.stateChanges.create':
          case 'Asset.healthcheckEvents.stateChanges.update':
            for (let healthcheckId in data.body) {
              broadcastEvent(data.body[healthcheckId], healthcheckId, event, 'healthcheckEventId');
            }
            break;
          default:
            console.log(data);
        }
      });
    }

    function broadcastEvent(data, eventId, eventType, idName) {
      data.created = new Date(data.created);
      if (data.modified) {
        data.modified = new Date(data.modified);
      }
      if (data.from) {
        data.from = new Date(data.from);
      }

      broadcast(eventId, data, eventType, idName || 'eventId');
    }

    function broadcast(entityId, content, type, idName) {
      idName = idName || 'tagId';

      const event = {
        content: content,
      };

      event[idName] = entityId;

      $rootScope.$broadcast(type, event);
    }
  }
})();
